<template>
  <v-container id="meta-quality-completed-phone-analytics">
    <advanced-table :title="title" :headers="headers" :endpoint="endpoint" :transform="transform" :loading="loading" :refresh="refresh" @success="success" @fail="fail" />
  </v-container>
</template>

<script>
  import AdvancedTable from '@/views/components/common/AdvancedTable';
  import { displayDateISO } from '@/util/DateUtil';

  export default {
    name: 'MetaQualityCompletedPhoneAnalytics',
    components: { AdvancedTable },
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        title: this.$t('metaReport.metaQuality.completedPhone.title'),
        headers: [
          {
            text: this.$i18n.t('metaReport.metaQuality.completedPhone.column.withPhone'),
            align: 'start',
            filterable: false,
            sortable: true,
            value: 'withPhone'
          },
          {
            text: this.$i18n.t('metaReport.metaQuality.completedPhone.column.withoutPhone'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'withoutPhone'
          },
          {
            text: this.$i18n.t('metaReport.metaQuality.completedPhone.column.completedManually'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'completedManually'
          },
          {
            text: this.$i18n.t('metaReport.metaQuality.completedPhone.column.performance'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'performance'
          },
          {
            text: this.$i18n.t('metaReport.metaQuality.completedPhone.column.date'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'date'
          }
        ],
        endpoint: 'analytics/meta/quality/phone/completed',
        transform: {
          performance: (performance) => (Math.round(performance * 100, 2) / 100) + '%',
          date: displayDateISO
        }
      };
    },
    methods: {
      success () {
        this.$emit('success');
      },
      fail (error, message) {
        this.$emit('fail', error, message);
      }
    }
  };
</script>
