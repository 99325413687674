<template>
  <v-container id="meta-quality-late-orders-analytics">
    <advanced-table :title="title" :headers="headers" :endpoint="endpoint" :transform="transform" :loading="loading" :refresh="refresh" @success="success" @fail="fail" />
  </v-container>
</template>

<script>
  import AdvancedTable from '@/views/components/common/AdvancedTable';
  import { displayDateISO, secondToHHmm, WEEKDAY } from '@/util/DateUtil';

  export default {
    name: 'MetaQualityLateOrdersAnalytics',
    components: { AdvancedTable },
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        title: this.$t('metaReport.metaQuality.lateOrders.title'),
        headers: [
          {
            text: this.$i18n.t('metaReport.metaQuality.lateOrders.column.location'),
            align: 'start',
            filterable: true,
            sortable: true,
            value: 'locationName'
          },
          {
            text: this.$i18n.t('metaReport.metaQuality.lateOrders.column.avgDelay'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'avgDelay'
          },
          {
            text: this.$i18n.t('metaReport.metaQuality.lateOrders.column.orders'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'count'
          },
          {
            text: this.$i18n.t('metaReport.metaQuality.lateOrders.column.weekday'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'weekday'
          },
          {
            text: this.$i18n.t('metaReport.metaQuality.lateOrders.column.date'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'date'
          }
        ],
        endpoint: 'analytics/meta/quality/orders/late',
        transform: {
          avgDelay: secondToHHmm,
          weekday: (weekday) => String(WEEKDAY[weekday]),
          date: displayDateISO
        }
      };
    },
    methods: {
      success () {
        this.$emit('success');
      },
      fail (error, message) {
        this.$emit('fail', error, message);
      }
    }
  };
</script>
