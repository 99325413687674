<template>
  <v-container id="meta-quality-production-events-analytics">
    <advanced-table :title="title" :headers="headers" :endpoint="endpoint" :transform="transform" :loading="loading" :refresh="refresh" @success="success" @fail="fail" />
  </v-container>
</template>

<script>
  import AdvancedTable from '@/views/components/common/AdvancedTable';
  import { phoneInt } from '@/util/PhoneUtil';
  import { displayDateISO, displayHourISO } from '@/util/DateUtil';

  export default {
    name: 'MetaQualityProductionEventsAnalytics',
    components: { AdvancedTable },
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        title: this.$t('metaReport.metaQuality.productionEvents.title'),
        headers: [
          {
            text: this.$i18n.t('metaReport.metaQuality.productionEvents.column.priority'),
            align: 'start',
            filterable: false,
            sortable: true,
            value: 'priority'
          },
          {
            text: this.$i18n.t('metaReport.metaQuality.productionEvents.column.locationName'),
            align: 'start',
            filterable: true,
            sortable: true,
            value: 'locationName'
          },
          {
            text: this.$i18n.t('metaReport.metaQuality.productionEvents.column.name'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'name'
          },
          {
            text: this.$i18n.t('metaReport.metaQuality.productionEvents.column.description'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'description'
          },
          {
            text: this.$i18n.t('metaReport.metaQuality.productionEvents.column.from'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'from'
          },
          {
            text: this.$i18n.t('metaReport.metaQuality.productionEvents.column.to'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'to'
          },
          {
            text: this.$i18n.t('metaReport.metaQuality.productionEvents.column.delay'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'delay'
          },
          {
            text: this.$i18n.t('metaReport.metaQuality.productionEvents.column.order'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'orderName'
          },
          {
            text: this.$i18n.t('metaReport.metaQuality.productionEvents.column.user'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'user'
          },
          {
            text: this.$i18n.t('metaReport.metaQuality.productionEvents.column.supervisor'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'supervisor'
          },
          {
            text: this.$i18n.t('metaReport.metaQuality.productionEvents.column.date'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'date'
          }
        ],
        endpoint: 'meta/production/events',
        transform: {
          priority: (priority) => this.$t('metaReport.metaQuality.productionEvents.priority.' + String(priority)),
          from: (date) => displayHourISO(date),
          to: (date) => displayHourISO(date),
          orderName: (orderName) => '<a href="' + this.$router.resolve('/search').href + '?name=' + encodeURIComponent(orderName) + '">' + String(orderName) + '</a>',
          user: (user) => '<a href="tel:' + phoneInt(String(user.phoneNumber)) + '">' + (user.firstName ?? '') + ' ' + (user.lastName ?? '') + '</a>',
          supervisor: (user) => (user.firstName ?? '') + ' ' + (user.lastName ?? ''),
          date: (date) => displayDateISO(date)
        }
      };
    },
    methods: {
      success () {
        this.$emit('success');
      },
      fail (error, message) {
        this.$emit('fail', error, message);
      }
    }
  };
</script>
